@import "./node_modules/bootstrap/scss/bootstrap"

.page-header
  text-align: center

.hidden
  display: none

.visible
  display: block

.steps > li
  font-size: larger


.record_button
  text-align: center !important
  margin: 40px 0
  cursor: pointer

.playback
  margin: 40px 0

.feedbackBtn
  cursor: pointer

.rl_results
  margin: 20px 0

.sl_results
  margin: 20px 0

.emotion
  margin-bottom: 30px

#wizard
  margin-bottom: 150px

#error
  width: 100%
  white-space: pre-wrap       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap    /* Opera 7 */
  word-wrap: break-word       /* Internet Explorer 5.5+ */

